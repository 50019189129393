import React from 'react'
import { SiWhatsapp } from 'react-icons/si';

const ModalWhatsapp = () => {
  return (
    <div className="fixed z-20 right-4 bottom-4 lg:right-8 lg:bottom-8 ">
      <a
        href="https://wa.me/+919052106666"
        target='_blank'
        rel='noreferrer'
        className="bg-[#25D366] w-11 h-11 sm:h-12 sm:w-12 lg:h-14 lg:w-14 rounded-full text-white flex justify-center items-center lg:text-4xl md:text-3xl cursor-pointer text-2xl "
      >
        <SiWhatsapp className="animate-pulse hover:scale-105" />
      </a>
    </div>
  );
}

export default ModalWhatsapp