import React, { useState } from "react";
import Breadcrumb from "../components/breadcrumb";
import { FaMapMarkerAlt } from "react-icons/fa";
import { serviceBrands, showroomBrands } from "../json/index";

const Locations = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  // const [selectedTab2, setSelectedTab2] = useState(0);

  return (
    <div>
      <img
        src={require("../assets/others/channels4_banner.jpg")}
        className="object-cover w-full h-[40vh]"
        alt=""
      />
      <Breadcrumb pageName="Locations" />
      <div className="py-10 bg-gray-50 ">
        <div className="container px-1 py-6 mx-auto rounded-lg lg:max-w-7xl">
          <h1 className="mb-6 text-4xl font-semibold">Location</h1>
          <div className="flex flex-wrap gap-2 mb-5 md:mt-5 lg:mb-8">
            {showroomBrands.map((brand, index) => (
              <button
                key={index}
                onClick={() => setSelectedTab(index)}
                className={`px-2 md:px-6 lg:px-8 py-2 rounded-lg focus:outline-none flex-grow ${
                  selectedTab === index
                    ? "bg-secondary text-white shadow-lg"
                    : "bg-secondary-light border border-secondary"
                }`}
              >
                {brand.name}
              </button>
            ))}
          </div>
          {/* <h4>Showrooms</h4> */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {showroomBrands[selectedTab]?.locations.map((location, index) => (
              <div
                key={index}
                className="flex flex-col justify-between p-4 duration-300 bg-white rounded-lg shadow-md cursor-default group hover:scale-95 hover:bg-secondary"
              >
                <div className="">
                  <h2 className="mb-2 text-xl font-semibold">
                    {location.name}
                  </h2>{" "}
                  <div className="flex gap-2 pb-3 text-sm text-gray-700 group-hover:text-white">
                    {location.address}
                  </div>
                  {location.personName && (
                    <p className="text-sm text-gray-900 group-hover:text-white">
                      <span className="mr-1 ">Manager:</span>{" "}
                      {location.personName}
                    </p>
                  )}
                  {location.phone && (
                    <div className="flex text-sm text-gray-900 group-hover:text-white ">
                      <div className="mr-2 ">Phone:</div>{" "}
                      <div className="">
                        {/* {location.phone} */}
                        {selectedTab === 4 ? "+91 8297551111" : "+91 9052106666"}
                      </div>
                    </div>
                  )}
                  {/* {location.phone && ( */}
                  {/* <div className="flex pt-2 text-sm text-gray-900 group-hover:text-white ">
                    <div className="mr-2 ">Timming:</div>{" "}
                    <div className="">
                      9:30 AM to 8 PM - MON to SAT <br /> 10.00 AM to 5 PM - SUN
                    </div>
                  </div> */}
                  {/* )} */}
                  {location.email && (
                    <p className="pb-4 text-sm text-gray-700 group-hover:text-white">
                      <span className="mr-2 ">Email:</span>{" "}
                      <span className="lowercase">{location.email}</span>
                    </p>
                  )}
                </div>
                <div className="mt-2 border rounded-lg ">
                  <a
                    href={location.map}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center gap-2 pt-3 pb-2 text-sm group-hover:text-white"
                  >
                    <FaMapMarkerAlt className="text-secondary group-hover:text-white" />{" "}
                    View in Google Map{" "}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <hr />
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3">
            {serviceBrands[selectedTab]?.locations.map((location, index) => (
              <div
                key={index}
                className="flex flex-col justify-between p-4 duration-300 bg-white rounded-lg shadow-md cursor-default group hover:scale-95 hover:bg-secondary"
              >
                <div className="">
                  <h2 className="mb-2 text-xl font-semibold">
                    {location.name}
                  </h2>{" "}
                  <div className="flex gap-2 pb-3 text-sm text-gray-700 group-hover:text-white">
                    {location.address}
                  </div>
                  {location.personName && (
                    <p className="text-sm text-gray-900 group-hover:text-white">
                      <span className="mr-1 ">Manager:</span>{" "}
                      {location.personName}
                    </p>
                  )}
                  {location.phone && (
                    <div className="flex text-sm text-gray-900 group-hover:text-white ">
                      <div className="mr-2 ">Phone:</div>{" "}
                      <div className="">
                        {/* {location.phone} */}
                      +91  9052106666
                      </div>
                    </div>
                  )}
                  {/* {location.phone && ( */}
                  {/* <div className="flex pt-2 text-sm text-gray-900 group-hover:text-white ">
                    <div className="mr-2 ">Timming:</div>{" "}
                    <div className="">
                      9:30 AM to 8 PM - MON to SAT <br /> 10.00 AM to 5 PM - SUN
                    </div>
                  </div> */}
                  {/* )} */}
                  {location.email && (
                    <p className="pb-4 text-sm text-gray-700 group-hover:text-white">
                      <span className="mr-2 ">Email:</span>{" "}
                      <span className="lowercase">{location.email}</span>
                    </p>
                  )}
                </div>
                <div className="mt-2 border rounded-lg ">
                  <a
                    href={location.map}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center gap-2 pt-3 pb-2 text-sm group-hover:text-white"
                  >
                    <FaMapMarkerAlt className="text-secondary group-hover:text-white" />{" "}
                    View in Google Map{" "}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="container px-1 py-6 mx-auto rounded-lg lg:max-w-7xl">
          <h1 className="mb-6 text-4xl font-semibold">Service</h1>
          <div className="flex flex-wrap gap-2 mb-5 md:mt-5 lg:mb-8">
            {serviceBrands.map((brand, index) => (
              <button
                key={index}
                onClick={() => setSelectedTab2(index)}
                className={`px-2 md:px-6 lg:px-8 py-2 rounded-lg focus:outline-none flex-grow ${
                  selectedTab2 === index
                    ? "bg-secondary text-white shadow-lg"
                    : "bg-secondary-light border border-secondary"
                }`}
              >
                {brand.name}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {serviceBrands[selectedTab2]?.locations.map((location, index) => (
              <div
                key={index}
                className="flex flex-col justify-between p-4 duration-300 bg-white rounded-lg shadow-md cursor-default group hover:scale-95 hover:bg-secondary"
              >
                <div className="">
                  <h2 className="mb-2 text-xl font-semibold">
                    {location.name}
                  </h2>{" "}
                  <div className="flex gap-2 pb-3 text-sm text-gray-700 group-hover:text-white">
                    {location.address}
                  </div>
                  {location.personName && (
                    <p className="text-sm text-gray-900 group-hover:text-white">
                      <span className="mr-1 ">Manager:</span>{" "}
                      {location.personName}
                    </p>
                  )}
                  {location.phone && (
                    <div className="flex text-sm text-gray-900 group-hover:text-white ">
                      <div className="mr-2 ">Phone:</div>{" "}
                      <div className="">
                        {/* {location.phone} 
                        90521 06666
                      </div>
                    </div>
                  )}
                 
                  {location.email && (
                    <p className="pb-4 text-sm text-gray-700 group-hover:text-white">
                      <span className="mr-2 ">Email:</span> {location.email}
                    </p>
                  )}
                </div>
                <div className="mt-2 border rounded-lg ">
                  <a
                    href={location.map}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center gap-2 pt-3 pb-2 text-sm group-hover:text-white"
                  >
                    <FaMapMarkerAlt className="text-secondary group-hover:text-white" />{" "}
                    View in Google Map{" "}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Locations;
