import Slider from "../components/Slider";
import TwoVehCard from "../components/TwoVehCard";
import BookForm from "../components/book-form";
import Breadcrumb from "../components/breadcrumb";
// import Card2 from "../components/card2";
import { model } from "../json";

const Nissan = () => {
  const final = model[13].map((item) => item.name);
  const slides = [
    {
      image:
        "https://www-asia.nissan-cdn.net/content/dam/Nissan/in/vehicles/x-trail/overview/X-TRAIL-Desktop-Banner-3000-x-1300.jpg.ximg.full.hero.jpg",
    },
    {
      image: require("../assets/others/Nissan-Magnite-May-VLP-Desktop-2880-x-2048.jpg"),
    },
    {
      image:
        "https://www-asia.nissan-cdn.net/content/dam/Nissan/in/vehicles/magnite/geza-special-edition/Nissan-Pace-GEZA-CVT-July-Desktop---3000-x-1300-v1.jpg.ximg.full.hero.jpg",
    },
    {
      image:
        "https://www-asia.nissan-cdn.net/content/dam/Nissan/in/vehicles/magnite/offers/Nissan-Magnite-July-Monsoon-Desktop-Without-copy-3000-x-1300.jpg.ximg.full.hero.jpg",
    },
  ];
  return (
    <>
      {/* <img
        src={require("../assets/others/Nissan-Magnite-May-VLP-Desktop-2880-x-2048.jpg")}
        className="object-cover max-w-full max-h-[52vh] w-full"
        alt=""
      /> */}
      <Slider slides={slides} />
      <Breadcrumb pageName="Nissan" />
      <div className="grid gap-10 px-1 mx-auto my-10 md:grid-cols-3 max-w-7xl">
        <div className="col-span-2">
          <p className="text-2xl font-semibold">Nissan</p>
          <p className="mt-5 text-black/80">
            A name that reverberates across time and terrain, Nissan has woven
            its legacy into the automotive fabric. From the rugged trails of the
            Patrol to the electric whispers of the Ariya, this brand has left an
            indelible mark. India, too, has been a canvas for this symphony of
            engineering and design. Imagine the sun-kissed streets of Mumbai,
            where the Micra danced through traffic with nimble grace. Or the
            winding roads of Himachal Pradesh, where the Terrano conquered
            mountain passes, its headlights piercing the mist. And who can
            forget the iconic Skyline? A legend born on the racetracks, its
            turbocharged heart beating in sync with adrenaline-fueled dreams.
            The GT-R, an unyielding force of nature, left tire marks on the
            asphalt and etched itself into folklore. But beyond speed and power,
            Nissan is about family road trips—the X-Trail carrying memories of
            laughter, picnics, and starlit nights.
          </p>

          <p className="mt-10 mb-2 text-2xl font-semibold">Orange Auto Group</p>
          <p className="text-black/80">
            Welcome to Orange Motors, your authorized car dealer for Nissan
            India Cars. We proudly serve customers in NH5 and Kancherapalem,
            offering a wide range of Nissan vehicles. As a top Nissan dealer, we
            are dedicated to providing exceptional service and an unmatched car
            buying experience. Our reputation as the best Nissan showroom and
            car dealer is built on our commitment to quality and customer
            satisfaction. Visit Orange Motors today and discover why we are the
            preferred choice for Nissan enthusiasts.
          </p>
        </div>
        <div className="col-span-2 p-2 lg:col-span-1">
          <BookForm final={final} />
        </div>
      </div>

      <div className="grid gap-5 py-10 mx-auto lg:grid-cols-3 max-w-7xl md:grid-cols-2 ">
        {model[13].map((item) => (
          <TwoVehCard
            key={item.id}
            id={item?.id}
            position="1"
            banner={item?.pic}
            title={item?.name}
            price={item?.price}
            fuel={item?.fuel}
            transmission={item?.transmission}
            engine={item?.cc}
            category={item?.variants[0]}
            brochure={item?.brochure}
            weight={item?.weight}
            variants={item?.variants}
          />
          // <Card2
          //   key={item.id}
          //   id={item?.id}
          //   position="1"
          //   banner={item?.pic}
          //   title={item?.name}
          //   price={item?.price}
          //   fuel={item?.fuel}
          //   transmission={item?.transmission}
          //   engine={item?.cc}
          //   brochure={item?.brochurePdf}
          //   dimensions={item?.dimensions}
          //   variants={item?.variants}
          // />
        ))}
      </div>
    </>
  );
};

export default Nissan;
