import Slider from "../components/Slider";
import BookForm from "../components/book-form";
import Breadcrumb from "../components/breadcrumb";
import Card from "../components/card";
import { model } from "../json";

const Tata = () => {
  const final = model[0].map((item) => item.name);
  const slides = [
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/inner-banner-2?$B-1920-667-D$&fit=crop&fmt=webp",
    },
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/racer-inner-banner?$B-1920-667-D$&fit=crop&fmt=webp",
    },
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/inner-banner-4?$B-1920-667-D$&fit=crop&fmt=webp",
    },

    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/punch-2023-kv?$B-1920-667-D$&fit=crop&fmt=webp",
    },
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/nexon-kv-highway?$B-1920-667-D$&fit=crop&fmt=jpg",
    },
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/banner-harrier?$B-1920-667-D$&fit=crop&fmt=webp",
    },
    {
      image:
        "https://s7ap1.scene7.com/is/image/tatamotors/safari-new-banner?$B-1920-667-D$&fit=crop&fmt=webp",
    },
  ];
  return (
    <>
      {/* <img
        src={require("../assets/others/tata_banner.jpg")}
        className="object-cover max-w-full"
        alt=""
      /> */}
      <Slider slides={slides} />
      <Breadcrumb pageName="Tata Motors" />
      <div className="container grid gap-10 px-1 mx-auto my-10 md:grid-cols-3 lg:max-w-7xl">
        <div className="col-span-2">
          <p className="text-2xl font-semibold">Tata Motors</p>
          <p className="mt-5 text-black/80">
            As one of India’s foremost home-grown automotive brands, we are
            delighted to have reached this significant milestone for our
            passenger vehicles segment. We are honoured to have the legendary
            Mr. Anupam Kher as the narrator of the glorious journey of a brand
            that is a legend in itself. This film is a testament to not only our
            evolutionary growth over the last 30 years but also the growth that
            India has seen as a nation through this period. This narration
            celebrates Tata Motors and all those who have supported us in these
            years, as we continue to provide class-defining products to our
            consumers, thereby allowing us to be thought leaders in the realm of
            design, safety and performance.
          </p>

          <p className="mt-10 mb-2 text-2xl font-semibold">Orange Auto Group</p>
          <p className="text-black/80">
            Welcome to Orange Tata, your premier authorized car dealer for Tata
            Motors Personal Vehicles. Renowned for our exceptional sales and
            customer service, we have expanded to multiple locations, including
            Erragadda, Attapur, Kharkana, Medchal, Medak, Hafeezpet,
            Sanathnagar, and more. As the best Tata showroom, we offer an
            extensive range of Tata vehicles to suit every need and preference.
            At Orange Tata, our mission is to provide a seamless car buying
            experience, making us the top choice for Tata Motors enthusiasts.
            Visit us today and drive home your dream car.
          </p>
        </div>
        <div className="col-span-2 p-2 lg:col-span-1">
          <BookForm final={final} />
        </div>
      </div>

      <div className="grid gap-5 py-10 mx-auto lg:grid-cols-3 max-w-7xl md:grid-cols-2 xl:grid-cols-4">
        {model[0].map((item) => (
          <Card
            key={item.id}
            id={item?.id}
            position="0"
            banner={item?.pic}
            title={item?.name}
            price={item?.price}
            fuel={item?.fuel}
            transmission={item?.transmission}
            engine={item?.cc}
          />
        ))}
      </div>
    </>
  );
};

export default Tata;
